:root {
    --color-primary: #0E7139;
    --color-light: #fff;
    --color-dark: #313131;
    --color-red: #C51F33;
    --color-warning: #FFD000;
    
    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f8f9fb;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;
    
    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;
    
    --radius: 20px;
    --background: linear-gradient(14deg, #3fc1be 0%, #3fc1bf6c);
    --color-shadow: 0 7px 14px 0 #3fc1bf49;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Maven Pro', sans-serif;
}

*,
*::after,
a::before {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

/* custom scroll bar */

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-track {
    background: var(--color-white);
}

::-webkit-scrollbar-thumb {
    background: var(--color-grey-light-2);
    transition: all 0.3s;
    border-radius: 10px;
    border: 2px solid var(--color-light);
    transition: all 0.5s;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-grey-dark-3);
    border: 2px solid var(--color-light);
    transition: all 0.5s;
}

::-webkit-scrollbar-thumb:active {
    background: var(--color-primary);
}

/* General Styles */
label {
    display: block;
    margin-bottom: 1rem;
}

button {
    cursor: pointer;
}

.title {
    font-size: 1.6rem;
    margin: 0;
}

.card {
    margin-top: 2rem;
    background-color: var(--color-light);
    padding: 2rem;
    border-radius: var(--radius);
}

.card__title {
    font-weight: 500;
}

.table {
    margin-top: 1rem;
    width: 100%;
}

.table__wrapper {
    width: 100%;
    overflow-x: scroll;
}

.table thead {
    border: 2px solid var(--color-grey-light-2);
}

.table th {
    padding: 1rem;
    text-align: left;
    white-space: nowrap;
}

.table td {
    padding: 1rem;
    border:1px solid var(--color-grey-light-3);
}

.table tr:nth-child(even) {
  background-color: var(--color-grey-light-2); }

.pagination {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.filter {
    margin-top: 2rem;
    border: 1px solid var(--color-grey-light-3);
    border-radius: var(--radius);
    padding: 1rem;
}

.label {
    margin-bottom: .5rem;
    display: block;
}

.primary_h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: var(--color-dark);
    font-weight: 500;
}

/* rich text editor css */
.editorClassName {
    min-height: 15rem;
}
.draftjs-editor-wrapper {
    border: 1px solid var(--color-grey-dark-3);
    padding: 1rem;
}

/* dynamic delete button css */
.dynamic-delete-button {
    position: relative;
    top: 2px;
    margin: 0 8px;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}