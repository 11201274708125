.main {
    background-color: #edf0f5;
    min-height: 100vh;

    &__body {
        margin-top: 75px;
        margin-left: 270px;
        padding: 2rem;
    }
}

.header {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    background-color: var(--color-light);
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 100;
}

.logo {
    width: 6rem;
    height: 6rem;
}

.avatar {
    width: 40px;
    border-radius: 100%;
}

.user {
    display: flex;

    &__text {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
    }
}

.name {
    font-weight: bold;
}

.sidebar {
    background-color: var(--color-light);
    min-width: 270px;
    max-width: 270px;
    position: fixed;
    top: 75px;
    left: 0px;
    height: calc(100% - 75px);
    overflow-x: scroll;

    ul {
        padding: 2rem;
        margin-top: 2rem;
        list-style: none;
        display: flex;
        flex-direction: column;
        height: 100%;

        li {
            padding: 1rem 2rem;
            border-radius: 5px;
            color: var(--color-dark);
            display: flex;
            align-items: flex-end;
            margin-bottom: 1rem;
            cursor: pointer;

            svg {
                margin-right: 1.5rem;
                color: #a6aed4;
            }
        }
    }
}

.selected {
    background: var(--color-primary);
    color: var(--color-light) !important;

    svg {
        color: var(--color-light) !important;
    }
}

.logout {
    margin-top: auto;
    margin-bottom: 2rem;
    color: var(--color-red);
}