.image_gallery {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;

    &_item {
        padding: 3%;
        position: relative;

        .icon {
            position: absolute;
            right: 10%;
            top: 10%;
            border: none;
            background: transparent;
            cursor: pointer;
        }

        & img {
            width: 15rem;
            height: 15rem;
            cursor: grabbing;
        }

        & video {
            width: 96%;
            height: 15rem;
            cursor: grabbing;
        }
    }
}