.file_upload {
    background-color: var(--color-light);
    width: 100%;
    margin: 0 auto;
}

.image_upload_wrap {
    margin-top: 10px;
    border: 1px dashed var(--color-primary);
    position: relative;
}

.file_upload_input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.drag_text {
    text-align: center;

    h3 {
        font-size: 1.4rem;
        text-transform: uppercase;
        color: var(--color-dark);
        margin-top: 1rem;
        padding: 60px 0;
    }
}