.formItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        margin-right: 1rem;
        white-space: nowrap;
        font-weight: 500;
        margin-bottom: 0;
    }
}

.discount_price_box,
.normal_price_box {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}


.fulfillment_channel {
    display: flex;
    gap: 1.5rem;
}

.channel_label {
    white-space: nowrap;
    font-weight: 700;
    margin-bottom: 0;
}

// product image section css
.image_upload_container {
    display: flex;
    gap: 5rem;
}


// custom upload button
.input_file_wrapper {
    margin-top: 2rem;

    label {
        font-weight: 700;
        margin-left: 0.5rem;
        font-size: 1.7rem;
    }
}

.input_file_and_files {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    // align-items: center;
    // gap: 3rem;
}

.input_file {
    background-color: #CCCCCC;
    cursor: pointer;
}

.input_files {
    flex: 1;

    img {
        height: 9.2rem;
        object-fit: cover;
        margin-right: 1rem;
    }
}

.upload_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem;

    span {
        font-weight: 700;
    }
}

.upload_label_bottom {
    margin-top: 5px;
    font-weight: 500;
}

.uploaded_images_and_videos {
    margin-top: 2rem;
}

.uploaded_urls {
    margin-top: 1rem;
}

.submit_images {
    margin-top: 2rem;
}