.products {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 1rem 2rem;
    flex-direction: row !important;

    h2 {
        margin-bottom: 0;
    }
}

.table_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
        margin-bottom: 0;
    }
}

.export {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
        color: var(--color-primary);
        cursor: pointer;
        font-weight: 500;
    }
}