.loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
        box-shadow: none;
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
        box-shadow: none;
    }
}

.icon {
    width: 8rem;
    height: 8rem;
    animation: pulsate 1s infinite;
}