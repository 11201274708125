.action_btn {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.upload_image_container {
    label {
        font-weight: 600;
    }
}

.image_group_container {
    margin-top: 1rem;
    width: 100%;
    display: grid;
    place-items: center;

    img {
        height: 10rem;
        width: auto;
    }
}