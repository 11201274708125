// .card {
//     background-color: var(--color-light);
//     padding: 1rem;
//     border-radius: 5px;
//     display: flex;
//     flex-direction: column;

//     &__header {
//         display: flex;
//         align-items: center;
//         font-weight: 500;
//         color: var(--color-primary);
//         margin-bottom: 2rem;

//         svg {
//             margin-right: 1rem;
//         }
//     }
// }

// .info {
//     display: block;
//     margin: .5rem 0;
// }

.landing_card {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}